import React from "react"
import WebinarPrivacyPolicy from "../components/privacy-policy"
import SEO from "../components/seo"
import Layout from "../components/layout"

const PrivacyPolicy = ({ location }) => (
  <Layout location={location}>
    <SEO title="Privacy Policy"></SEO>
    <WebinarPrivacyPolicy></WebinarPrivacyPolicy>
  </Layout>
)

export default PrivacyPolicy
